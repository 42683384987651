
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.item {
    position: relative;
    &:after {
        content: "";
        display: block;
        width: 50px;
        height: 3px;
        background-color: #ff9900;
        margin: 30px auto 0;
    }
}
.desc {
    color: #686868;
}
.mainTitle {
    @apply text-3xl;
    @include xxl {
        @apply text-4xl;
    }
}
